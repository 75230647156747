<template>
  <v-card elevation="0">
    <v-card-title>
      {{'action.forgot-password'| t}}
    </v-card-title>

    <v-card-text>
      <v-text-field
        dense
        outlined
        autofocus
        class="email"
        maxlength="50"
        counter
        @keyup.enter="sendResetPasswordEmail"
        :label="$t('data.email')"
        :rules="[
          value => $helper.validator.requiredRule(value),
          value => $helper.validator.emailRule(value),
        ]"
        v-model="email"
      ></v-text-field>

      <v-btn
        block
        color="primary"
        class="mb-2"
        @click="sendResetPasswordEmail"
        :loading="loading"
        :disabled="disabled"
      >
        {{'action.send_verify.email'| t}}
      </v-btn>

      <a
        @click="hasVerifyCodeAlready"
        color="primary"
        class="mr-8"
        disabled
      >
        {{'otp.has_verify_code_already'| t}}
      </a>

      <router-link :to="{ name: 'login' }" class="text-decoration-none">
        {{'action.login'| t}}
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('action.forgot-password'),
    }
  },
  data: () => ({
    email: null,
    loading: false,
    recaptcha: null,
  }),
  beforeDestroy() {
    this.email = null
    this.$recaptchaLibrary.destroy()
  },
  async mounted() {
    this.$recaptchaLibrary.load()
  },
  methods: {
    async sendResetPasswordEmail() {
      this.loading = true
      try {
        await this.$recaptchaLibrary.execute(token => {
          this.recaptcha = token
        })
        await this.$api.collection.siteAdminAccountApi.sendResetPasswordEmail(this.email, this.recaptcha)
        this.$snotify.success(null, this.$t('send_mail.successfully'))
        this.loginViaVerifyCode()
      } catch (error){
        console.warn(error)
        let message = this.$t('error.unexpect')
        if(error.status == 404) message = this.$t('error.send_reset_password_mail.404')
        if(error.status == 409) message = this.$t('error.send_reset_password_mail.409')
        if(error.status == 431) message = this.$t('notify.recaptcha.failure')
        this.$snotify.error(message, this.$t('error.send_mail.failure'))
      } finally {
        this.loading = false
      }
    },
    hasVerifyCodeAlready() {
      if(this.disabled) {
        this.$snotify.warning(null, this.$t('otp.has_verify_code_already.warning'))
        return
      }

      this.loginViaVerifyCode()
    },
    loginViaVerifyCode() {
      this.$router.push({
        name: 'login-via-otp',
        query: {
          target: this.email,
        },
      })
    },
  },
  computed: {
    disabled() {
      if(!this.$helper.validator.required(this.email)) return true
      if(!this.$helper.validator.email(this.email)) return true
      return false
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
